import React from 'react'
import Video from './Component/Video'
import DoctorContent from './Component/DoctorContent'
import Video1 from './Component/Video1'

const Home = () => {
  return (
    <>
      <Video />
      <DoctorContent />
      <Video1 />
      <div className='Services_Home'>
        <div className='container'>
          <h2 className='text-center'>Services</h2>
          <div className='underline'></div>
          <div className='row Sub_Row'>
            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='Fast-Track-Knee-Replacement.png' alt='Fast Track Knee Replacement'></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Fast Track Knee Replacement</h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>Dr. Srinivas Polisetty is a highly respected orthopedic surgeon in Guntur, known for his expertise in advanced knee replacement techniques. With over two decades of experience, Dr. Polisetty has gained recognition for performing fast-track knee replacement surgeries that help patients recover quickly and comfortably.</p>
                      <a href='' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='Therapy-Free Total Knee Replacement.jpg' alt='Therapy-Free Total Knee Replacement'></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Therapy-Free TKR</h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>Therapy-Free Total Knee Replacement.This innovative technique aims to simplify the recovery process by minimizing or eliminating the need for postoperative physiotherapy, enabling patients to regain mobility more naturally and comfortably.</p>
                      <a href='' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='revision-knee-replacement.jpg' alt='Revision Total Knee Replacement'></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Revision TKR</h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>Dr. Srinivas Polisetty, a leading orthopedic surgeon in Guntur, is renowned for his expertise in Revision Total Knee Replacement Surgery. This specialized procedure is designed for patients who have previously undergone knee replacement.</p>
                      <a href='' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='Arthroscopy (Key Hole Surgery).png' alt='Revision Total Knee Replacement'></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Arthroscopy (Key Hole Surgery)</h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>Arthroscopy is a minimally invasive surgical procedure used to diagnose and treat joint problems. During arthroscopy, a surgeon inserts a small camera, called an arthroscope, into the joint through a tiny incision. The camera displays images on a screen, allowing the surgeon to examine the inside of the joint in real-time and perform precise interventions with specialized instruments.</p>
                      <a href='' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='Regenerative.jpg' alt='Revision Total Knee Replacement'></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Regenerative Knee Treatment</h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>Regenerative knee treatments offer minimally invasive options to relieve knee pain, repair tissue, and delay or avoid surgery. These therapies leverage the body’s healing abilities, focusing on natural tissue repair and regeneration for issues like osteoarthritis, ligament injuries, and cartilage damage.</p>
                      <a href='Regenerative_knee_treatment' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 col-sm-12'>
              <div className='card'>
                <img src='Hip_joint_Replacement.jpg' alt='Revision Total Knee Replacement' height={250}></img>
                <div className='row Sub_Row'>
                    <div className='col-md-3 col-3'>
                      <h5 className='vertical_Hedding'>Fast Track Hip Joint Replacement </h5>
                      <div className='underline1'></div>
                    </div>
                    <div className='col-md-9 col-9'>
                      <p className='p-2'>"Fast-track" hip joint replacement is a streamlined approach to hip replacement surgery that aims to improve recovery time, minimize hospital stay, and reduce post-operative pain. This approach combines advanced surgical techniques, optimized pain management, and enhanced patient care protocols, leading to quicker mobilization and a faster return to daily activities.</p>
                      <a href='Fast_track_hip_joint_replacement_in_Guntur' className='Home_Service_Link'> &#8594;</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home