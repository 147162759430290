import React from 'react'

const fracture_management_guntur = () => {
    return (
        <>
            <div className='Service_Banner'>
                <div className='container'>
                    <h1>Fracture management</h1>
                </div>
            </div>
            <div className='service_Content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-2'>
                            <div className='Service_Image'>
                                <img src='Fracture Management.webp' alt='Fracture Management'></img>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-2'>
                            <p>Fracture management involves the treatment and care of broken bones to restore normal function, promote healing, and prevent complications. The approach to managing fractures depends on the location, type, severity of the fracture, and the patient’s overall health.</p>
                            <h3>Types of Fractures</h3>
                            <ul>
                                <li>Closed Fracture: The bone breaks but does not penetrate the skin.</li>
                                <li>Open (Compound) Fracture: The bone breaks and pierces through the skin, increasing the risk of infection.</li>
                                <li>Comminuted Fracture: The bone is shattered into multiple pieces.</li>
                                <li>Greenstick Fracture: A partial fracture, common in children, where the bone bends and cracks.</li>
                                <li>Stress Fracture: A small crack in the bone, often due to repetitive stress or overuse.</li>
                            </ul>
                        </div>
                    </div>
                    <h3>Fracture Management Techniques</h3>
                    <h6>1. Immobilization</h6>
                    <ul>
                        <li>Casting or Splinting: Immobilizes the fractured bone to allow it to heal. This is commonly used for stable fractures.</li>
                        <li>Traction: Uses weights to align bones in cases where casting may not provide proper alignment.</li>
                    </ul>
                    <h6>2. Closed Reduction</h6>
                    <ul>
                        <li>A non-surgical procedure where the physician manually realigns the bone without an incision. Often used for simple fractures.</li>
                    </ul>
                    <h6>3. Open Reduction and Internal Fixation (ORIF)</h6>
                    <ul>
                        <li>Involves surgical exposure of the fracture site to align the bones and secure them with metal rods, screws, or plates. This is common for complex fractures.</li>
                    </ul>
                    <h6>4. External Fixation</h6>
                    <ul>
                        <li>Metal pins or screws are inserted into the bone and attached to an external frame to stabilize the fracture. This method is often used for severe open fractures or cases where internal fixation is not possible.</li>
                    </ul>
                    <h3>Recovery and Rehabilitation</h3>
                    <p>Fracture recovery may include rest, pain management, and physical therapy to restore mobility and strength. Recovery time varies depending on the type of fracture and the treatment method, typically ranging from a few weeks to several months. Proper management is essential to ensure correct healing, prevent deformities, and reduce long-term complications.</p>
                </div>
            </div>
        </>
    )
}

export default fracture_management_guntur