import React from 'react'
import videoBg from '../assets/KNR.mp4';
const Video1 = () => {
  return (
     <div className='videoview1 shadow-sm  bg-body rounded'>
            <div className="overlay"></div>
            <video className="video"src={videoBg} autoPlay loop muted />
            <div className="bg-text1 p-5 container">
                <h1>AREAS OF EXPERTISE</h1>
                <img src='' alt='leg image'></img>
                <div className='row mt-3'>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-1 mb-1'>
                            <a href='' className='p-1' style={{color:'#e7bb2a'}}><h4>Title1Title1Title1Title1</h4></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-1 mb-1 '>
                        <a href='' className='p-1' style={{color:'#e7bb2a'}}><h4>Title1Title1Title1Title1</h4></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-1 mb-1'>
                        <a href='' className='p-1' style={{color:'#e7bb2a'}}><h4>Title1Title1Title1Title1</h4></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <div className='card mt-1 mb-1'>
                        <a href='' className='p-1' style={{color:'#e7bb2a'}}><h4>Title1Title1Title1Title1</h4></a>
                        </div>
                    </div>
                </div>
               
            </div>
            {/* <div className="content">
                <h1>Welcome</h1>
                <p>To my site.</p>
            </div> */}
        </div>
  )
}

export default Video1