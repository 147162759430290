import React from 'react'

const DoctorContent = () => {
    return (
        <>
            <div className="doctorcontent">
                <div className='layer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12 details1'>
                                <img src='doctor image.png' alt='doctor image' ></img>
                            </div>
                            <div className='col-md-6 col-sm-12 details'>
                                <h1 style={{color:'#e7bb2a'}}>Orthopedic Surgeon In Guntur</h1>
                                <h1 className='name'>Meet Dr Srinivas</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                                <a href='' id='button'><button type="button" class="btn btn-outline-warning mb-5">SEE BIO</button></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default DoctorContent