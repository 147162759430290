import React from 'react'
import { FaHome } from "react-icons/fa";
import { FaMobile } from "react-icons/fa";
import { BsTelephoneInboundFill } from "react-icons/bs";

const Footer = () => {
    return (
        <>
            <div className='Footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12'>

                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div>
                                <FaHome  style={{color:'#a92525'}}/>
                                <p>Address: 15-11-154 Near Best Price Mangalagiri Road , c/o Vedanta hospital, Guntur, Andhra Pradesh 522001</p>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            
                        <BsTelephoneInboundFill  style={{color:'#a92525'}}/>
                            <p className='mt-2'>Contact : +91 77606 03287</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer