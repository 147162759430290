import React from 'react'

const Blogs = () => {
    return (
        <>
            <div className='Service_Banner'>
                <div className='container'>
                    <h1>Blogs</h1>
                </div>
            </div>
            <div className='container mt-5 mb-5' >
               <h3 className='text-center p-5'style={{marginTop:"100px"}}>Coming Soon....</h3>
            </div>
        </>
    )
}

export default Blogs